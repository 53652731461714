import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registryFinalTokenApi = createApi({
  reducerPath: "registryfinaltoken",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  tagTypes: ["RegistryFinalToken"],
  endpoints: (build) => ({
    getRegistryFinalTokenDetails: build.query({
        query: (params) => {
            return {
              url: "rs/registry/final-token",
              params: params,
              method: "GET",
            };
          },
      providesTags: ["RegistryFinalToken"],
    }),
  }),
});

export const {
  useGetRegistryFinalTokenDetailsQuery,
} = registryFinalTokenApi;
