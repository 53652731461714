// store.js
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query'
import { registryApi } from './registryapi';
import { registryProjectDetailsApi } from './registryprojectdetailsapi';
import { registryMethodApi } from './registrymethodapi';
import { registryOrgApi } from './registryorgapi';
import { registryDocumentApi } from './registrydocumentapi';
import { registryPreEntitlementApi } from './registrypreentitlementapi';
import { registryFinalTokenApi } from './registryfinaltokenapi';
import { registryUserApi } from './registryuserapi';

const store = configureStore({
  reducer: {
    [registryApi.reducerPath]: registryApi.reducer,
    [registryProjectDetailsApi.reducerPath]: registryProjectDetailsApi.reducer,
    [registryMethodApi.reducerPath]: registryMethodApi.reducer,
    [registryOrgApi.reducerPath]: registryOrgApi.reducer,
    [registryDocumentApi.reducerPath] : registryDocumentApi.reducer,
    [registryPreEntitlementApi.reducerPath] : registryPreEntitlementApi.reducer,
    [registryFinalTokenApi.reducerPath] : registryFinalTokenApi.reducer,
    [registryUserApi.reducerPath] : registryUserApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware().concat(
    registryApi.middleware,
    registryProjectDetailsApi.middleware,
    registryMethodApi.middleware,
    registryOrgApi.middleware,
    registryDocumentApi.middleware,
    registryPreEntitlementApi.middleware,
    registryFinalTokenApi.middleware,
    registryUserApi.middleware,
    ),
});

setupListeners(store.dispatch)

export default store