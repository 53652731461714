import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registryUserApi = createApi({
  reducerPath: "registryUserApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: ["RegistryUser"],
  endpoints: (build) => ({
    getRegistryUserDetails: build.query({
      query: (id) => `/rs/registry/userdetails/${id}`,
      providesTags: ["RegistryUser"],
    }),
    
  }),
});
export const { useGetRegistryUserDetailsQuery } = registryUserApi;