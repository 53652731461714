import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registryPreEntitlementApi = createApi({
  reducerPath: "registrypreentitlement",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  tagTypes: ["RegistryPreEntitlement"],
  endpoints: (build) => ({
    getRegistryPreEntitlementDetails: build.query({
      query: (params) => {
        return {
          url: "rs/registry/pre-entitlement/transaction",
          params: params,
          method: "GET",
        };
      },
      providesTags: ["RegistryPreEntitlement"],
    }),
  }),
});

export const {
  useGetRegistryPreEntitlementDetailsQuery,
} = registryPreEntitlementApi;
