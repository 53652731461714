import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import {
  Typography,
  Card,
  Row,
  Col,
  Statistic,
  Table,
  Button,
  Spin,
  Popover,
  Avatar
} from "antd";
import { DownloadOutlined, LoadingOutlined, InfoCircleOutlined, ShopOutlined} from "@ant-design/icons";
import store from "./store/store";
import {
  registryProjectDetailsApi,
  useGetRegistryProjectDetailsQuery,
  useGetRegistryProjectYieldDetailsQuery,
} from "./store/registryprojectdetailsapi";
import { registryMethodApi } from "./store/registrymethodapi";
import { RegistryDisplayOrganizationName } from "./RegistryDisplayOrgName";
import { registryDocumentApi } from "./store/registrydocumentapi";
import axios from "axios";
import { registryFinalTokenApi } from "./store/registryfinaltokenapi";
import moment from 'moment';

function RegistryProjectDetailsPageView() {
  const { id } = useParams();
  console.log(id);
  const history = useHistory();

  const [isSpinning, setIsSpinning] = useState(false);

  const [methodData, setMethodData] = useState();
  const [documentList, setDocumentList] = useState();
  const [yieldDataList, setYieldDataList] = useState();
  const [certificateDetails, setCertificateDetails] = useState();
  const { data, isLoading } = useGetRegistryProjectDetailsQuery(id, {
    skip: !id,
  });

  const handleGoBack = () => {
    history.push("/");
  };

  async function fetchData() {
    try {
      setIsSpinning(true);

      var { data: methodDetails, isLoading: isMethodDetailsLoading } =
        await store.dispatch(
          registryMethodApi.endpoints.getRegistryMethodDetails.initiate(
            data?.data?.[0]?.method_id
          )
        );

      var { data: yieldData } = await store.dispatch(
        registryProjectDetailsApi.endpoints.getRegistryProjectYieldDetails.initiate(
          data?.data?.[0]?._id
        )
      );

      // Extracting Final Token using project_id
      const { data: finalTokenData, isLoading: isFinalTokenDataLoading } =
        await store.dispatch(
          registryFinalTokenApi.endpoints.getRegistryFinalTokenDetails.initiate(
            { project_id: id },
            { skip: !id }
        )
      );

      var { data: documentIdDetails } = await store.dispatch(
        registryProjectDetailsApi.endpoints.getRegistryProjectDocumentIds.initiate(
          data?.data?.[0]?._id
        )
      );

      const docIds = documentIdDetails?.docids?.map(Number) || [];
      var { data: documentListData } = await store.dispatch(
        registryDocumentApi.endpoints.getRegistryDocumentDetails.initiate({
          docids: JSON.stringify(docIds),
        })
      );

      setDocumentList(documentListData);
      setYieldDataList(yieldData);
      setCertificateDetails(finalTokenData);

      if (!isMethodDetailsLoading) {
        setMethodData(methodDetails?.[0]);
      }
    } catch {
    } finally {
      setIsSpinning(false);
    }
  }

  async function getOrgIdsByAssigneeType(record, assigneeType) {
    const filteredAssignments = record?.data?.[0]?.assignments?.filter(
      (assignment) => assignment?.assignee_type === assigneeType
    );
    const orgID = filteredAssignments?.map(
      (assignment) => assignment?.organization
    );
    return <RegistryDisplayOrganizationName orgid={orgID} place="table" />;
  }

  const [verifier, setVerifier] = useState(null);
  const [validator, setValidator] = useState(null);
  const [sponsor, setSponsor] = useState(null);

  useEffect(() => {
    console.log("isLoading....", isLoading);
    if (!isLoading) {
      fetchData();
      getOrgIdsByAssigneeType(data, "Verifier").then((result) =>
        setVerifier(result)
      );
      getOrgIdsByAssigneeType(data, "Validator").then((result) =>
        setValidator(result)
      );
      getOrgIdsByAssigneeType(data, "Project Sponsor").then((result) =>
        setSponsor(result)
      );
    }
  }, [data, isLoading]);

  const onDownloadDocument = (record) => {
    axios
      .get("/rs/registry/registrydocument/" + record.ID, {
        responseType: "blob",
      })
      .then((blob) => {
        console.log("blob", blob);
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", record.filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
      });
  };

  const format = (value) => (
    <div>
      {!isSpinning && (
        <Typography.Text style={{ fontSize: 18 }}>{value}</Typography.Text>
      )}
    </div>
  );

  const handleViewDetails = () => {
    history.push({
      pathname: `/${id}/transaction`,
    });
  };

  const YieldColumns = [
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      sorter: (a, b) => a.period.localeCompare(b.period),
      defaultSortOrder: "ascend",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      title: "Estimated Yield (tCo2e)",
      dataIndex: "co2_estimated",
      key: "co2_estimated",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record ? record : "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <span>
          Issued Credits (tCo2e)
          <Popover content="Amount of issued credits for this project per GHG yield period, expressed in total amount of tCO2e.">
            <InfoCircleOutlined style={{ marginLeft: 5 }} />
          </Popover>
        </span>
      ),
      dataIndex: "co2_realized",
      key: "co2_realized",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record ? record : "-"}
          </Typography.Text>
        );
      },
    },
    {
      title: "Available Pre-Credits",
      dataIndex: "pre_credits",
      key: "pre_credits",
      render: (record) => {
        const formattedRecord = record != null ? (record === 0 ? "-" : null) || (record % 1 !== 0 ? record.toFixed(4).replace(/\.?0+$/, '') : record.toFixed(0)) : "-";
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {formattedRecord}
          </Typography.Text>
        );
      },
    },
    {
      title: "Available Credits",
      dataIndex: "credits",
      key: "credits",
      render: (record) => {
        const formattedRecord = record != null ? (record === 0 ? "-" : null) || (record % 1 !== 0 ? record.toFixed(4).replace(/\.?0+$/, '') : record.toFixed(0)) : "-";
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {formattedRecord}
          </Typography.Text>
        );
      },
    },
    {
      title: "Storage Duration (Years)",
      dataIndex: "storage_duration",
      key: "storage_duration",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record}
          </Typography.Text>
        );
      },
    },
  ];

  const certificateRetirementColumns = [
    {
      title: "Retirement Date",
      dataIndex: "updated",
      key: "updated",
      sorter: (a, b) => a.updated - b.updated,
      width: 200,
      defaultSortOrder: "descend",
      render: (updated, record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            <span>
              {updated
                ? moment.utc(updated["$date"]).local().format("DD MMM, YYYY")
                : record?.created
                ? moment
                    .utc(record?.created["$date"])
                    .local()
                    .format("DD MMM, YYYY")
                : undefined}
            </span>
          </Typography.Text>
        );
      },
    },
    {
      title: (
        <span>
          Proba Tradable Credit Bundle ID
          <Popover content={
          <>
            Proba Credits are transferred and claimed in credit bundles (Proba Tradable Credit Bundles).<br />
            Each credit bundle has a specific ID.<br />
            A beneficiary has the option to claim a full bundle or a part of it.
          </>
          }>
            <span className="info-icon" style={{ marginLeft: 5 }}>
              <InfoCircleOutlined />
            </span>
          </Popover>
        </span>
      ),
      dataIndex: "finaltoken_id",
      key: "finaltoken_id",
      width: 250,
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            PTCB - {record}
          </Typography.Text>
        );
      },
    },
    {
      title: "Retired Credits",
      dataIndex: "no_of_token",
      key: "no_of_token",
      width: 200,
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      title: "Beneficiary",
      dataIndex: "beneficiary_name",
      key: "beneficiary_name",
      width: 250,
      render: (record) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Avatar
              style={{ backgroundColor: "#87d068", marginRight: "8px" }}
              icon={<ShopOutlined />}
            />
            {record}
          </div>
        );
      },
    },
    {
      title: "Retirement Purpose",
      dataIndex: "certificate_retirement_purpose",
      key: "certificate_retirement_purpose",
      width: 300,
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      title: "Period",
      dataIndex: "yield_period",
      width: 200,
      key: "certificate_yield_periods",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record && record.length > 0 ? Array.from(new Set(record)).join(', ') : ''}
          </Typography.Text>
        );
      },
    },
  ];

  const documentColumns = [
    {
      title: "Document Type",
      dataIndex: "doctype",
      key: "doctype",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      title: "Document Name",
      dataIndex: "filename",
      key: "filename",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      key: "actions",
      title: "Download",
      render: (record) => {
        return (
          <>
            <Row gutter={[10, 10]}>
              <Col>
                <DownloadOutlined
                  onClick={() => {
                    onDownloadDocument(record);
                  }}
                />
              </Col>
            </Row>
          </>
        );
      },
    },
  ];

  return (
    <div>
      {!isLoading && (
        <Spin
          size="large"
          spinning={isSpinning}
          indicator={
            <LoadingOutlined style={{ fontSize: 24, color: "#36B28D" }} spin />
          }
        >
          <div style={{ height: "80vh" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography.Title level={2} style={{ fontFamily: "Lexend" }}>
                {data?.data?.[0]?.name}
              </Typography.Title>
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={handleGoBack}
                  style={{ backgroundColor: "#36B28D", color: "white" }}
                >
                  Back
                </Button>
              </div>
            </div>
            <div>
              <Typography.Paragraph
                style={{ fontSize: 16, fontFamily: "Lexend" }}
              >
                {data?.data?.[0]?.ghg_project_summary}
              </Typography.Paragraph>
            </div>
            <div>
              <Card className="shadow-card" style={{ borderRadius: 8 }}>
                <Row gutter={[100, 20]}>
                  <Col span={12}>
                    <Statistic
                      title="Project ID"
                      value={id}
                      formatter={(value) => format(value)}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Project Status"
                      value={data?.data?.[0]?.status}
                      formatter={(value) => format(value)}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Methodology"
                      value={methodData}
                      formatter={(value) => format(value)}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Project Developer"
                      value={
                        <RegistryDisplayOrganizationName
                          orgid={data?.data?.[0]?.organization}
                          place="table"
                        />
                      }
                      formatter={(value) => format(value)}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Project Sponsor"
                      value={sponsor}
                      formatter={(value) => format(value)}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Validator"
                      value={validator}
                      formatter={(value) => format(value)}
                    />
                  </Col>
                  <Col span={12}>
                    <Statistic
                      title="Verifier"
                      value={verifier}
                      formatter={(value) => format(value)}
                    />
                  </Col>
                </Row>
              </Card>
            </div>
            <div style={{display: 'flex', alignItems: 'center'}}>
            <Typography.Title
              level={2}
              style={{ fontFamily: "Lexend", marginTop: "2rem", marginRight: 'auto' }}
            >
              GHG Yield Information, Issued and Available Credits
            </Typography.Title>
            </div>
            <div>
              <Card className="shadow-card" style={{ borderRadius: 8 }}>
                <Table
                  dataSource={yieldDataList}
                  columns={YieldColumns}
                  pagination={{
                    pageSizeOptions: ["10", "50", "100"],
                    showSizeChanger: true,
                  }}
                />
              </Card>
            </div>

            <Typography.Title
              level={2}
              style={{ fontFamily: "Lexend", marginTop: "2rem" }}
            >
              Retirement Details
            </Typography.Title>
            <div>
            <Typography.Paragraph style={{ fontSize: 16, fontFamily: 'Lexend' }}>
            Proba Credits are retired when a retiring entity or a beneficiary makes a claim. Here you can view the Proba Credits that have been retired for this project.
            </Typography.Paragraph>
            </div>
            <div>
              <Card className="shadow-card" style={{ borderRadius: 8 }}>
                <Table
                  dataSource={certificateDetails?.data}
                  columns={certificateRetirementColumns}
                  pagination={{
                    pageSizeOptions: ["10", "50", "100"],
                    showSizeChanger: true,
                  }}
                />
              </Card>
            </div>

            <Typography.Title
              level={2}
              style={{ fontFamily: "Lexend", marginTop: "2rem" }}
            >
              Project Documents
            </Typography.Title>
            <div>
              <Card className="shadow-card" style={{ borderRadius: 8 }}>
                <Table
                  dataSource={documentList}
                  columns={documentColumns}
                  pagination={{
                    pageSizeOptions: ["10", "50", "100"],
                    showSizeChanger: true,
                  }}
                />
              </Card>
            </div>
          </div>
        </Spin>
      )}
    </div>
  );
}

export default RegistryProjectDetailsPageView;
