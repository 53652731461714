import "./App.css";

import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import RegistryLandingPageView from './RegistryLandingPageView';
import RegistryProjectDetailsPageView from './RegistryProjectDetailsPageView';
import RegistryTransactionPageView from "./RegistryTransactionPageView";
import "antd/dist/reset.css";
import { Layout, Menu } from "antd";

const { Header, Content, Footer, Sider } = Layout;

function App() {

  return (

    <Layout style={{
      height: '100vh'
    }}>
      <Header
        style={{
          position: "fixed",
          zIndex: 1,
          width: "100%",
          backgroundColor: '#123B2E'
        }}
      >
        <div className="logo">
          <img src="/negative_logo.png" alt="logo" style={{ height: 36 }} />
        </div>
        <Menu
          theme="dark"
          mode="horizontal"
          style={{ backgroundColor: '#123B2E' }}
        />

        {/* {isConnected && (
          <button style={{marginTop:24}} className="button">
            Mint
         </button>
         )} */}

      </Header>

      <Layout>
        <Content
          className="site-layout"
          style={{
            padding: '0 10px',
            marginTop: 58,
            backgroundColor: '#f2f4f5',
          }}
        >
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              minHeight: 500,
              backgroundColor: '#f2f4f5',
            }}
          >
            <Router>
              <Switch>
                <Route exact path="/" component={RegistryLandingPageView} />
                <Route path="/:id/transaction" component={RegistryTransactionPageView} />
                <Route path="/:id" component={RegistryProjectDetailsPageView} />
              </Switch>
            </Router>
          </div>

        </Content>
      </Layout>
    </Layout >

  );
}

export default App;
