import { FolderViewOutlined } from "@ant-design/icons";
import { Card, Table, Typography } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";

import { RegistryDisplayOrganizationName } from "./RegistryDisplayOrgName";
import { useGetRegistryQuery } from "./store/registryapi";
import TruncateText from "./TruncateText";

function RegistryLandingPageView() {
  const { data, isLoading, isError } = useGetRegistryQuery();

  const history = useHistory();

  const handleViewDetails = (record) => {
    const projectId = record?.project_id;
    history.push({
      pathname: `/${projectId}`,
    });
  };
  const columns = [
    {
      title: "Project ID",
      dataIndex: "project_id",
      key: "project_id",
      render: (record) => {
        return <TruncateText text={record} length={26} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (record) => {
        return <TruncateText text={record} length={34} />;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 120,
      render: (record) => {
        return (
          <Typography.Text
            style={{
              textTransform: "capitalize",
              fontSize: 16,
              fontFamily: "Lexend",
            }}
          >
            {record}
          </Typography.Text>
        );
      },
    },
    {
      key: "Project Developer",
      title: "Organization",
      dataIndex: "organization",
      width: 280,
      render: (record) => {
        return <RegistryDisplayOrganizationName orgid={record} place="table" />;
      },
    },
    {
      title: "Project Status",
      key: "status",
      dataIndex: "status",
      sorter: (a, b) => a?.status?.localeCompare(b?.status),
      filters: [
        { text: 'Active', value: 'Active' },
        { text: 'Validator Review', value: 'Validator Review' },
        { text: 'Public Review', value: 'Public Review' }
      ],
      onFilter: (value, record) => record?.status?.includes(value),
    },    
    {
      title: "Region",
      dataIndex: "region",
      key: "region",
      render: (record) => {
        return <TruncateText text={record} />;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => {
        return (
          <>
            <FolderViewOutlined
              onClick={() => {
                handleViewDetails(record);
              }}
              style={{ marginRight: 10 }}
            />
          </>
        );
      },
    },
  ];
  return (
    <div style={{ height: "80vh" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography.Title level={2} style={{ fontFamily: "Lexend" }}>
          Proba Registry
        </Typography.Title>
      </div>
      <div>
        <Typography.Paragraph style={{ fontSize: 16, fontFamily: "Lexend" }}>
          The Proba Registry contains a large selection of information on
          certified projects, issued and retired credits. Information on
          projects and credits can be accessed by scrolling down and clicking on
          one of the specific projects below.
        </Typography.Paragraph>
        <Typography.Paragraph style={{ fontSize: 16, fontFamily: "Lexend" }}>
          A Proba account is required for any entity wishing to register
          projects or issue, claim, or transfer Proba credits. Visit{" "}
          <a href="https://app.proba.earth/">https://app.proba.earth/</a> to
          register your account.
        </Typography.Paragraph>
        <Typography.Paragraph style={{ fontSize: 16, fontFamily: "Lexend" }}>
          For more information on Proba, please visit{" "}
          <a href="https://proba.earth/">https://proba.earth/</a>
        </Typography.Paragraph>
      </div>

      <div>
        <Card className="shadow-card" style={{ borderRadius: 8 }}>
          {isLoading ? (
            <div>Loading...</div>
          ) : isError ? (
            <div>Error fetching data</div>
          ) : (
            <Table
              columns={columns}
              dataSource={data?.data}
              pagination={{
                pageSizeOptions: ["10", "50", "100"],
                showSizeChanger: true,
              }}
              onRow={(record) => ({
                onClick: () => {
                  handleViewDetails(record);
                },
                style: { cursor: "pointer" },
              })}
            />
          )}
        </Card>
      </div>
    </div>
  );
}

export default RegistryLandingPageView;
