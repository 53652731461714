import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import store from './store/store';

const probaTheme = {
  token: {
    colorPrimary: "#36B28D",
    colorError: "#EC4D4F",
    colorWarning: "#FFF962",
    colorSuccess: "#52c41a",
    colorInfo: "#1890ff",
  }
};

const container = document.getElementById("root");
const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <ConfigProvider theme={probaTheme}>
      <App />
    </ConfigProvider>
  </Provider>
);
