import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registryProjectDetailsApi = createApi({
  reducerPath: "registryprojectdetails",
  baseQuery: fetchBaseQuery({
    baseUrl: "/",
  }),
  tagTypes: ["RegistryProjectDetails"],
  endpoints: (build) => ({
    getRegistryProjectDetails: build.query({
      query: (id) => `rs/registry/project/${id}`,
      providesTags: ["RegistryProjectDetails"],
    }),
    getRegistryProjectDocumentIds: build.query({
      query: (id) => `rs/registry/document/${id}`,
      providesTags: ["RegistryProjectDetails"],
    }),
    getRegistryProjectYieldDetails:build.query({
      query: (id) => `rs/registry/yield/${id}`,
      providesTags: ["RegistryProjectDetails"],
    }),
  }),
});

export const {
  useGetRegistryProjectDetailsQuery,
  useGetRegistryProjectDocumentIdsQuery,
  useGetRegistryProjectYieldDetailsQuery,
} = registryProjectDetailsApi;
