import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registryOrgApi = createApi({
  reducerPath: "registryOrgApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: ["RegistryOrganization"],
  endpoints: (build) => ({
    getRegistryOrganizationDetails: build.query({
      query: (id) => `/rs/registry/org/${id}`,
      providesTags: ["RegistryOrganization"],
    }),
    
  }),
});
export const { useGetRegistryOrganizationDetailsQuery } = registryOrgApi;