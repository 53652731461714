import React from "react";
import { Avatar, Col, Row, Typography } from "antd";
import { ShopOutlined } from "@ant-design/icons";
import { useGetRegistryOrganizationDetailsQuery } from "./store/registryorgapi";
import { useGetRegistryUserDetailsQuery } from "./store/registryuserapi";
import { skipToken } from "@reduxjs/toolkit/query";

export const RegistryDisplayOrganizationName = ({ orgid, userid, place }) => {
  const { data: userOrgId } = useGetRegistryUserDetailsQuery(userid ?? skipToken);
  
  const { data, isLoading:isOrgDetailsLoading } = useGetRegistryOrganizationDetailsQuery(orgid ?? userOrgId?.organization ?? skipToken);
  if(!isOrgDetailsLoading){
  }
  const displayFontSize = place === "table" ? 16 : 16
  return (
    <div>
      {!isOrgDetailsLoading && <Row align="middle" gutter={[8, 0]}>
        <Col>
          <Avatar
            style={{ backgroundColor: "#87d068" }}
            icon={<ShopOutlined />}
          />
        </Col>
        <Col>
          <Row>
            <Col>
              <Typography.Text style={{ fontSize: displayFontSize, fontFamily: 'Lexend' }}>
                {data?.organization}
              </Typography.Text>
            </Col>
          </Row>
        </Col>
      </Row>}
    </div>
  );
};
