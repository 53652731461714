import React, { useState, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Typography, Card, Table, Button, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {
  registryProjectDetailsApi,
  useGetRegistryProjectDetailsQuery,
  useGetRegistryProjectYieldDetailsQuery,
} from "./store/registryprojectdetailsapi";
import { useGetRegistryPreEntitlementDetailsQuery } from "./store/registrypreentitlementapi";
import { RegistryDisplayOrganizationName } from "./RegistryDisplayOrgName";
import moment from "moment";

function RegistryTransactionPageView() {
  const { id } = useParams();
  console.log("Id", id);
  const history = useHistory();

  const [isSpinning, setIsSpinning] = useState(false);

  const { data: projectData, isLoading } = useGetRegistryProjectDetailsQuery(
    id,
    {
      skip: !id,
    }
  );
  const { data: yieldData } = useGetRegistryProjectYieldDetailsQuery(
    projectData?.data?.[0]?._id,
    {
      skip: !projectData,
    }
  );

  // Extracting yield IDs from yieldData
  const yieldIdsList =
    yieldData?.map((yieldDetails) => yieldDetails?.yield_id) || [];

  const { data: preEntitlementData, isLoading: isPreEntilementDataLoading } =
    useGetRegistryPreEntitlementDetailsQuery(
      { yield_ids: yieldIdsList },
      { skip: !yieldIdsList.length }
    );

  if (!isPreEntilementDataLoading) {
    console.log("PreEntitlement Data", preEntitlementData);
  }

  const handleGoBack = () => {
    history.push({
      pathname: `/${id}`,
    });
  };

  const transactionColumns = [
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      render: (record) => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {record}
          </Typography.Text>
        );
      },
    },
    {
      title: "Organization",
      key: "organization",
      render: () => {
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {<RegistryDisplayOrganizationName orgid={projectData?.data?.[0]?.organization} place="table"/>}
          </Typography.Text>
        );
      },
    },
    {
      title: "Credit Type",
      key: "credittype",
      render: (record) => {
        return record.pre_credits !== null && record.pre_credits !== undefined
          ? "Proba Pre-Credit"
          : null;
      },
    },
    {
      title: "Quantity",
      dataIndex: "pre_credits",
      key: "pre_credits",
      render: (record) => {
        const formattedRecord = record != null ? (record === 0 ? "-" : null) || (record % 1 !== 0 ? record.toFixed(4).replace(/\.?0+$/, '') : record.toFixed(0)) : "-";
        return (
          <Typography.Text style={{ fontFamily: "Lexend", fontSize: 16 }}>
            {formattedRecord}
          </Typography.Text>
        );
      },
    },
  ];

  const filteredYieldData = yieldData?.filter(
    (data) => data?.pre_credits !== null && data?.pre_credits !== undefined
  );

  return (
    <div>
      {!isLoading && (
        <Spin
          size="large"
          spinning={isSpinning}
          indicator={
            <LoadingOutlined style={{ fontSize: 24, color: "#36B28D" }} spin />
          }
        >
          <div style={{ height: "80vh" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography.Title level={2} style={{ fontFamily: "Lexend" }}>
                {projectData?.data?.[0]?.name}
              </Typography.Title>
              <div style={{ textAlign: "right" }}>
                <Button
                  type="primary"
                  onClick={handleGoBack}
                  style={{ backgroundColor: "#36B28D", color: "white" }}
                >
                  Back
                </Button>
              </div>
            </div>

            <Typography.Title
              level={2}
              style={{ fontFamily: "Lexend", marginTop: "2rem" }}
            >
              Transaction
            </Typography.Title>
            <div>
              <Card>
                <Table
                  dataSource={filteredYieldData?.filter(record => record?.pre_credits !== null && record?.pre_credits !== 0)}
                  columns={transactionColumns}
                  pagination={{
                    pageSizeOptions: ["10", "50", "100"],
                    showSizeChanger: true,
                  }}
                />
              </Card>
            </div>
          </div>
        </Spin>
      )}
    </div>
  );
}

export default RegistryTransactionPageView;
