import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
export const registryDocumentApi = createApi({
  reducerPath: "registrydocumentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: ["RegistryDocument"],
  endpoints: (build) => ({
    getRegistryDocumentDetails: build.query({
      query: (params) => {
        return {
          url: "/rs/registry/document/publicdocuments",
          params: params,
          method: "GET",
        };
      },
      providesTags: ["RegistryDocument"],
    }),
    downloadDocument: build.query({
      query: (dochash) => `/rs/registry/registrydocument/${dochash}`,
      providesTags: ["RegistryDocument"],
    }),
  }),
});
export const { useGetRegistryDocumentDetailsQuery, useDownloadDocumentQuery } = registryDocumentApi;