import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registryApi = createApi({
  reducerPath: "registryApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: ["Registry"],
  endpoints: (build) => ({
    getRegistry: build.query({
      query: () => "/rs/registry/project",
      providesTags: ["Registry"],
    }),
    
  }),
});
export const { useGetRegistryQuery } = registryApi;

