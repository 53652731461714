import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const registryMethodApi = createApi({
  reducerPath: "registryMethodApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "",
  }),
  tagTypes: ["RegistryMethod"],
  endpoints: (build) => ({
    getRegistryMethodDetails: build.query({
      query: (id) => `/rs/registry/method/${id}`,
      providesTags: ["RegistryMethod"],
    }),
    
  }),
});
export const { useGetRegistryMethodDetailsQuery } = registryMethodApi;